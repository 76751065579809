import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { Subscribe } from './Subscribe.js';
import { Ajax } from '../ajax/Ajax';
import  config from '../config/Config';

class Footer extends Component {
	
	constructor(props) {
		super(props);
		this.state = {menu : []};		
	}
	
	componentWillMount() {
		var obj = this;
		Ajax.getFooterMenu().then(function(menu) {
			obj.setState({menu: menu.reverse()});			
		});
	}
	
	render() {
		var menu = this.state.menu;
		
		return (
		<div className="Footer">
			<Subscribe />
			<div className="FooterMenu">				
				{ menu ? menu.map(function(menuItem, key) {
						return (
							<Link key={key} to={"/page/" + menuItem.slug}>
								<div className="FooterMenuItem" dangerouslySetInnerHTML={ {__html: menuItem.title} } />
							</Link>
						);
					}) : null
				}
				<Link to={"/redeem"}><div className="FooterMenuItem" onClick={function() { window.location.href="/redeem" } }>Redeem code</div></Link>
				<div className="FooterCopyright" dangerouslySetInnerHTML={ {__html:  config.copyRightName + " &copy; " + new Date().getFullYear()} } />
				<div className="SocialMenu">
				<div className="SocialMenuItem Bandcamp"><a href={config.bandcamp} target="_blank" rel="noopener noreferrer"><FontAwesome name='bandcamp' /></a></div>
				<div className="SocialMenuItem Soundcloud"><a href={config.soundcloud} target="_blank" rel="noopener noreferrer"><FontAwesome name='soundcloud' /></a></div>
				<div className="SocialMenuItem Facebook"><a href={config.facebook} target="_blank" rel="noopener noreferrer"><FontAwesome name='facebook-official' /></a></div>
				<div className="SocialMenuItem Instagram"><a href={config.instagram} target="_blank" rel="noopener noreferrer"><FontAwesome name='instagram' /></a></div>
				<div className="SocialMenuItem Twitter"><a href={config.twitter} target="_blank" rel="noopener noreferrer"><FontAwesome name='twitter' /></a></div>
				<div className="SocialMenuItem Youtube"><a href={config.youtube} target="_blank" rel="noopener noreferrer"><FontAwesome name='youtube' /></a></div>
				<div className="SocialMenuItem Spotify"><a href={config.spotify} target="_blank" rel="noopener noreferrer"><FontAwesome name='spotify' /></a></div>				
				<div className="Clear"/>
			</div>
			</div>
			<div className="FooterClear" />
		</div>
		);
	}
}

export { Footer };
