import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import config from '../config/Config';
import { Link } from 'react-router-dom';

class Menu extends Component {
	
	constructor(props) {
		super(props)
		this.waiting = false;
		this.state = {cart: this.props.cartSize, showBlog: false, showMerch: false};
	}
	
	componentWillMount() {
		var obj = this;
	}
	
	render() {
		var logoSmallStyle = {backgroundImage: "url('" + config.logoSmall + "')"};
		return (
		<div className="MenuWrapper" id="menuwrap">
			<div className="Menu">
				<Link to="/"><div className={config.urbanWaves ? "MenuLogo" : "MenuLogoRJ"} style={logoSmallStyle}></div></Link>
				<div className="MenuItem"><Link to="/shop">Shop</Link></div>
				<div className="MenuItem"><Link to="/roster">Artists</Link></div>
				<div className="MenuItem"><a href={config.sisterLabelLink}>{config.sisterLabel}{config.sisterLabel === "Radio Juicy" ? ( <img src="/img/favicon-rj.png"/>) : null}</a></div>
				<div className="Clear"/>
			</div>
			<div className="ShopMenu">
				<div className="ShopMenuItem"><Link to="/cart"><FontAwesome name="shopping-bag"/></Link>{this.props.cartSize > 0 ? (<div className="CartCount">{this.props.cartSize}</div>) : null}</div>
				<div className="ShopMenuItem"><Link to="/account"><FontAwesome name="user"/></Link></div>
			</div>
			<div className="MenuAnnouncement">
			
			</div>
		</div>
		
		);
	}
}

export { Menu };
