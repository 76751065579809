import React, { Component } from 'react';
import { LandingRelease } from './LandingRelease';
import FontAwesome from 'react-fontawesome';

class Landing4 extends Component {
	
	constructor(props) {
		super(props);
		this.state = {ready: this.props.releases.length > 0, idx: 0, releases: this.props.releases, player: this.props.player, last: false, first: true};
		this.prev = this.prev.bind(this);
		this.next = this.next.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.releases.length != prevProps.releases.length) {
			this.setState({ready: this.props.releases.length > 0, releases: this.props.releases, player: this.props.player});
		}
	}
	
	next() {
		if (this.state.idx < this.state.releases.length - 1) {
			this.setState({idx: this.state.idx + 1});
		}
	}
	
	prev() {
		if (this.state.idx > 0) {
			this.setState({idx: this.state.idx - 1});
		}
	}
	
	render() {
		var obj = this;
		var ready = this.state.ready;
		var release = this.state.releases[this.state.idx];
		var player = this.state.player;
		return (
			<div className="Landing3">
				<div className="LandingReleases">

					{ ready && player ?  (
					<LandingRelease release={release} player={obj.state.player} >

					</LandingRelease>)
						: null }					
					{ready && this.state.idx > 0 ? (
					<div className="LandingArrowLeft" onClick={this.prev}><FontAwesome name="angle-double-left" /></div>
					) : null}

					{ready && this.state.idx < this.state.releases.length - 1 ? (
					<div className="LandingArrowRight" onClick={this.next}><FontAwesome name="angle-double-right" /></div>
					) : null}

				</div>
			</div>
		);
	}
}

export { Landing4 };