import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import config from '../config/Config';

import { CartPopup } from './CartPopup';

class ReleaseDetails extends Component {
	
	constructor(props) {
		super(props);
		this.state = {release: {}, variations: [], mp3s: [], artists: false, showAddedPopup: false, player: this.props.player};
		this.handleShowImage = this.handleShowImage.bind(this);
		this.mergeArtist = this.mergeArtist.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleAddToCart = this.handleAddToCart.bind(this);		
	}	
	
	componentWillMount() {
		var obj = this;
		var slug = this.props.match.params.slug;
		Ajax.getReleaseBySlug(slug).then(function(release) {
			obj.setState({release: release});
			if (release.variations.length > 0) {
				obj.setState({variations: []});
				for (var variationIdx in release.variations) {
					var variationId = release.variations[variationIdx];
					Ajax.getReleaseVariation(release.id, variationId).then(function (variation) {
						var newVariations = [...obj.state.variations, variation];
						newVariations.sort(function(a, b) { return a.name > b.name ? 1 : -1; });						
						obj.setState({variations : newVariations});
					});
				}
			}
			var mp3Slugs = release.mp3s;
			if (mp3Slugs.length > 0) {
				Ajax.getMp3s(mp3Slugs).then(function(mp3s) {
					for (var i = 0; i < mp3s.length; i++) {
						var mp3 = mp3s[i];
						if (mp3.nr === undefined) {
							mp3.nr = 1;
						}
					}
					mp3s.sort(function(a, b) { return a.nr - b.nr });
					obj.setState({mp3s : mp3s});
				});			
			}
			return release.artist;
		}).then(function(artistNames) {
			Ajax.getArtistsByName(artistNames).then(function(artists) {
				if (artists.length < artistNames );
				obj.setState({artists: artists});
			});
		});		
	}
/*
	componentDidUpdate(prevProps) {
		if(this.props.match.params.slug !== prevProps.release.slug) {
			this.setState({release: this.props.release, mp3s: []}, this.loadRelease);
		}
	} 
*/
	handleSelect(idx) {
		var obj = this;
		
		return () => {
			obj.state.player.setAlbum(obj.state.release, obj.state.mp3s, idx, true);
		}
	}
	
	handleShowImage(src) {
		$("#cover").css({"background-image" : "url('" + src + "')"});
	}
	
	mergeArtist(artistName) {		
		var artists = this.state.artists;
		for (var artistIdx in artists) {
			var artist = artists[artistIdx];
			if (artist.name.replace("&#038;", "&amp;") === artistName) 
				return artist;
		}
		return false;
	}
	
	handleAddToCart(itemId, varId, varSlug) {
		var obj = this;
		Ajax.addToCart(itemId, varId, varSlug).then(function(success) {
			obj.props.cartFunction();
		});
		// show popup
		this.setState({showAddedPopup: true});
		
	}
	
	render() {
		
		var title = this.state.release.title;
		var various = this.state.release.various;
		var artists = this.state.release.artist;
		var cover = this.state.release.cover;
		var imgs = this.state.release.img;
		var content = this.state.release.description;
		var variations = this.state.variations;
		var spotify = this.state.release.spotify;
		var bandcamp = this.state.release.bandcamp;
		var itunes = this.state.release.itunes;
		var youtube = this.state.release.youtube;
		var deezer = this.state.release.deezer;
		var tidal = this.state.release.tidal;
		var amazon = this.state.release.amazon;
		var hhv = this.state.release.hhv;
		var qrates = this.state.release.qrates;
		var mp3s = this.state.mp3s;
		var catalog = this.state.release.ref;
		var year = this.state.release.year;
		var releaseDate = this.state.release.releaseDate;
		var preorder = this.state.release.preorder;
		
		var releaseId = this.state.release.id;
		
		var obj = this;

		return (
		<div className="ReleaseDetails">
			<div className="ReleaseDetailsCover">
				<div className="ReleaseDetailsCoverMain" style={{ backgroundImage: "url('" + cover + "')"}} id="cover"></div>
				<div className="ReleaseDetailsCoverThumbs">
					{ imgs && (imgs.length > 1) ?
						imgs.map(function(img, key) {
							return (
							<div className="ReleaseDetailsCoverThumb" key={key} style={{ backgroundImage: "url('" + img + "')" /*.replace(".jpg", "-100x100.jpg") + "')"*/}} onClick={function() { obj.handleShowImage(img); }}></div>
							);
						}) :
						null	
					}	
				</div>
			</div>
			<div className="ReleaseDetailsText">
				<div className="ReleaseDetailsTitle" dangerouslySetInnerHTML={ {__html: title} } />
				<div className="ReleaseDetailsArtist">by 
				{ artists ? artists.map(function(artist, key) {
						var mergedArtist = obj.mergeArtist(artist);
						if (mergedArtist)
							return (<Link key={key} className="ReleaseDetailsArtistName" to={"/artist/" + mergedArtist.slug} dangerouslySetInnerHTML={ {__html: mergedArtist.name } }></Link>);
						else 
							return (<span key={key} className="ReleaseDetailsArtistName" dangerouslySetInnerHTML={ {__html: artist } } />)
					}) : null
				}
				</div>
				<div className="ReleaseDetailsLinks">
					{ spotify ? (<div className="ReleaseDetailsLink Spotify"><a href={spotify} target="_blank" rel="noopener noreferrer"><FontAwesome name="spotify" /></a></div>) : null }
					{ bandcamp ? (<div className="ReleaseDetailsLink Bandcamp"><a href={bandcamp} target="_blank" rel="noopener noreferrer"><FontAwesome name="bandcamp" /></a></div>) : null }
					{ itunes ? (<div className="ReleaseDetailsLink ITunes"><a href={itunes} target="_blank" rel="noopener noreferrer"><FontAwesome name="apple" /></a></div>) : null }
					{ youtube ? (<div className="ReleaseDetailsLink Youtube"><a href={youtube} target="_blank" rel="noopener noreferrer"><FontAwesome name="youtube" /></a></div>) : null }
					{ amazon ? (<div className="ReleaseDetailsLink Amazon"><a href={amazon} target="_blank" rel="noopener noreferrer"><FontAwesome name="amazon" /></a></div>) : null }
					{ deezer ? (<div className="ReleaseDetailsLink Deezer"><a href={deezer} target="_blank" rel="noopener noreferrer"><img src="/img/deezer.png" /></a></div>) : null }
					{ hhv ? (<div className="ReleaseDetailsLink"><a href={hhv} target="_blank" rel="noopener noreferrer"><img alt="hhvlogo" src="/img/hhv.png"/></a></div>) : null }
					{ qrates ? (<div className="ReleaseDetailsLink"><a href={qrates} target="_blank" rel="noopener noreferrer"><img alt="qrateslogo" src="/img/qrates.png"/></a></div>) : null }
				</div>
				
				{ preorder ? (<div className="ReleaseDetailsPreorder">PRE-ORDER (Release on {releaseDate})</div>) : null}
				
				<div className="ReleaseDetailsVariationsContainer">
				{ variations ? 
					variations.map(function(variation, key) {
						if(variation.inStock) {
							return (
							<div key={key} className="ReleaseDetailsVariation">
								<div className="ReleaseDetailsVariationAddToCart" onClick={function() {obj.handleAddToCart(releaseId, variation.id, variation.slug); }}>
									<div className="ReleaseDetailsVariationBuyNow"><FontAwesome name='cart-arrow-down' /></div>
									<div className="ReleaseDetailsVariationName">{variation.name}</div>								
									<div className="ReleaseDetailsVariationPrice">{variation.price > 0 ? "€ " + variation.price : "FREE"}</div>								
								</div>
								<div className="ReleaseDetailsVariationDescription"  dangerouslySetInnerHTML={ {__html: variation.description} } />
							</div>
							);
						}
						else {
							if (variation.name === "Vinyl") {
								if (hhv) {
									return (
									<div key={key} className="ReleaseDetailsVariation">
										<a href={hhv} target="_blank" rel="noopener noreferrer">
										<div className="ReleaseDetailsVariationAddToCart">
											<div className="ReleaseDetailsVariationBuyNow"><FontAwesome name='cart-arrow-down' /></div>
											<div className="ReleaseDetailsVariationName">{variation.name}</div>								
											<div className="ReleaseDetailsVariationPrice"></div>
										</div>
										</a>
										<div className="ReleaseDetailsVariationDescription">Buy at hhv.de</div>
									</div>
									)
								}
								else if (qrates) {
									return (
									<div key={key} className="ReleaseDetailsVariation">
										<a href={qrates} target="_blank" rel="noopener noreferrer">
										<div className="ReleaseDetailsVariationAddToCart">
											<div className="ReleaseDetailsVariationBuyNow"><FontAwesome name='cart-arrow-down' /></div>
											<div className="ReleaseDetailsVariationName">{variation.name}</div>								
											<div className="ReleaseDetailsVariationPrice"></div>
										</div>
										</a>
										<div className="ReleaseDetailsVariationDescription">Buy at QRates</div>
									</div>
									)
								}
								else {
									return (
									<div key={key} className="ReleaseDetailsVariation">
										<div className="ReleaseDetailsVariationOutOfStock">
											<div className="ReleaseDetailsVariationName">{variation.name}</div>
											<div className="ReleaseDetailsOutOfStock">out of stock</div>
										</div>
										<div className="ReleaseDetailsVariationDescription"  dangerouslySetInnerHTML={ {__html: variation.description} } />
									</div>
									);
								}
							}
						}
					})
					: null			
				}
				</div>
				<div className="AudioPlaylistItems">
					{ mp3s ? mp3s.map(function(mp3, key) {
						var entry = mp3.nr + ". " + mp3.title; 
						if (various)
							entry += " (" + mp3.artist + ")";
						return (
							<div key={key} className="AudioPlaylistItem" onClick={obj.handleSelect(key)}>
								<FontAwesome name="play"/>
								<span className="AudioPlaylistItemTitle" dangerouslySetInnerHTML={ {__html:entry} } />
								<span className="AudioPlaylistItemLength" dangerouslySetInnerHTML={ {__html:mp3.length} } />
							</div>
							);
						}) : null
					}
				</div>
				<div className="ReleaseDetailsContent" dangerouslySetInnerHTML={ {__html: content} } />
				<div className="ReleaseDetailsCopyright">{catalog}<br/>Released {releaseDate}<br/>{config.copyRightName} &copy; {year}</div>
			</div>
			{this.state.showAddedPopup ? (<CartPopup closeFunction={function() { obj.setState({showAddedPopup: false}); }}/>) : null}
		</div>
		);
	}
	
}


export { ReleaseDetails };
