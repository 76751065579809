import React, { Component } from 'react';
import config from "../config/Config";

class Cart extends Component {

	render() {
		return (
		<div className="Cart">
			<div className="CartImage"/>
			<iframe scrolling="no" title="cart" src={config.wp_base + "/cart/"} id="shop"/>
		</div>
		);
	}
}

export { Cart };
