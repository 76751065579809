import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

class Release extends Component {

	constructor(props) {
		super(props);
		this.state = {release: this.props.release, player: this.props.player, cover: this.props.cover, slug: this.props.slug, title: this.props.title, artist: this.props.artist};
	}

	componentDidUpdate(prevProps) {
		if(this.props.release.slug !== prevProps.release.slug) {
			this.setState({release: this.props.release, cover: this.props.cover, slug: this.props.slug, title: this.props.title, artist: this.props.artist});
		}
	}
	
	render() {
		var cover = this.state.cover;
		cover.includes("-scaled") ? cover = cover.replace("-scaled.jpg", "-300x300.jpg") : cover = cover.replace(".jpg", "-300x300.jpg");
		var backgroundStyle = {
			backgroundImage: "url(" + cover + ")",
			backgroundSize: "cover",
			backgroundPosition: "center center"
		};
		
		var obj = this;
		
		return (
			<div className="ReleaseWrap">
			<Link to={"/album/" + obj.state.slug}>
			<div className="Release" style={backgroundStyle}>
				<div className="ReleaseTitle" dangerouslySetInnerHTML={ {__html: this.state.title} } />
				<div className="ReleaseArtist" dangerouslySetInnerHTML={ {__html: "by " + this.state.artist.join(" &amp; ") } } />
				
				<div className="ReleaseBuyButton"></div>
			</div>
			</Link>
			<div className="ReleasePlayButtonOuter">
				<div className="ReleasePlayButton" onClick={(e) => {this.state.player.setAlbum(this.state.release, false, 0, true); }}><FontAwesome name="play"/></div>
			</div>
			</div>
		
		);
	}
}

export { Release };