import React, { Component } from 'react';

class MobileRelease extends Component {
	
	constructor(props) {
		super(props);
		this.state = {release: this.props.release, cover: this.props.cover, slug: this.props.slug, title: this.props.title, artist: this.props.artist};
	}

	componentDidUpdate(prevProps) {
		if(this.props.release.slug !== prevProps.release.slug) {
			this.setState({release: this.props.release, cover: this.props.cover, slug: this.props.slug, title: this.props.title, artist: this.props.artist});
		}
	}

	render() {
        var cover = this.state.cover;
		cover.includes("-scaled") ? cover = cover.replace("-scaled.jpg", "-300x300.jpg") : cover = cover.replace(".jpg", "-300x300.jpg");
		var backgroundStyle = {
			backgroundImage: "url(" + cover + ")",
			backgroundSize: "cover",
			backgroundPosition: "center center"
		};
		
		var obj = this;
		
		return (
		<div className="Release" style={backgroundStyle} onClick={function() {window.location.href = "/album/" + obj.props.slug}}>
			<div className="ReleaseTitle" dangerouslySetInnerHTML={ {__html: this.props.title} } />
			<div className="ReleaseArtist" dangerouslySetInnerHTML={ {__html: "by " + this.props.artist.join(" &amp; ") } } />
		</div>
		);
	}
}

export { MobileRelease };