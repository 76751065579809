import React, { Component } from 'react';
import config from '../config/Config';

class Account extends Component {
	
	render() {
		return (
		<div className="Account">
			<iframe scrolling="no" title="account" src={config.wp_base + "/my-account/"} id="shop"/>
		</div>
		);
	}
}

export { Account };
