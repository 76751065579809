import React, { Component } from 'react';

class Redeem extends Component {
	
	render() {
		var output = (
			<div className="Redeem">
				<div className="RedeemContent">
					<form id="getAlbum" action="/redeemsystem/backend/endpoints/getAlbum.php" method="GET">
						<p className="spaced"><div className="label">Code</div><input type="text" id="code" name="code" placeholder=""/></p>
						<p className="spaced"><input type="submit" value="REDEEM CODE"/></p>
					</form>
				</div>
			</div>);
		return output;
	}
}

export { Redeem };