import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RosterArtist extends Component {
	
	render() {
		var backgroundStyle = {
			backgroundImage: "url(" + this.props.cover + ")"//.replace("-scaled.jpg", "-300x300.jpg") + ")"			
		};
		
		var obj = this;
		
		return (
		<Link to={"/artist/" + obj.props.slug}>
			<div className="RosterArtist" style={ backgroundStyle }>
				<div className="RosterArtistName" dangerouslySetInnerHTML={ {__html: this.props.name} } />
			</div>
		</Link>
		);
	}
	
}

export { RosterArtist };