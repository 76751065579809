import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import { RosterArtist } from './RosterArtist';

class Roster extends Component {
	
	constructor(props) {
		super(props);
		this.state = {roster: [], page: 1, hasMore: true, waiting: false};
		this.waiting = false;
		this.perPage = 10000;
		this.interval = null;
		this._addArtists = this._addArtists.bind(this);
	}
	
	_addArtists() {
		var obj = this;
		obj.setState({page: obj.state.page + 1});
		Ajax.getArtists(obj.state.page, obj.perPage).then(function(parsedArtists) {
			var newArtists = obj.state.roster.slice();
			for (var idx in parsedArtists) {
				newArtists.push(parsedArtists[idx]);
			}
			obj.setState({roster: newArtists});
			
			if (parsedArtists.length < obj.perPage) {
				obj.setState({hasMore: false});
				obj.setState({waiting: false});
				//clearInterval(obj.interval);
			}								
		});
	}

	componentWillMount() {
		this.setState({waiting: true});
		//this.interval = setInterval(this._addArtists, 700);
		this._addArtists();
	}
	
	render() {
		var roster = this.state.roster;
		var output = (
		<div className="Roster">
		{
			roster ? roster.map(function(artist, key) {
				return (<RosterArtist key={key} name={artist.name} slug={artist.slug} cover={artist.cover} />);
			}) : null
		}
		{ this.state.waiting ? (<div className="Loader"><img src="/img/loader.gif" alt="LOADING" /></div>) : null }
		</div>
		)
		return output;
	}
	
}

export { Roster };