import React, { Component } from 'react';
import { MobileRelease } from './MobileRelease';

class MobileShop extends Component {
	
	constructor(props) {
		super(props);
		this.state = {releases: this.props.releases};	
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.releases.length != prevProps.releases.length) {
			this.setState({releases: this.props.releases});
		}
	}
	
	render() {
		var releases = this.state.releases;
		var output = (
		 
		<div className="Releases">
			{
				releases ? releases.map(function(release, key) {
					if (release.show)
						return (<MobileRelease key={key} release={release} title={release.title} artist={release.artist} cover={release.cover} slug={release.slug} />);
					else
						return null;
				}) : null
			}			
		</div>		
		)
		return output;
	}
}

export { MobileShop };