import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import config from '../config/Config';
import { Link } from 'react-router-dom';

class MobileMenu extends Component {
	
	render() {
		return (
		<div className="MobileMenu">
			<Link to="/"><div className="MobileMenuLogo">{config.urbanWaves ? "URBAN WAVES RECORDS" : "RADIO JUICY"}</div></Link>
			<div id="mobile-menu" className="dl-menuwrapper">
				<button className="dl-trigger">Open Menu</button>
				<ul className="dl-menu">
					<li>
						<Link to="/shop">SHOP</Link>
					</li>
					<li>
						<Link to="/roster">ARTISTS</Link>
					</li>
					<li>
						<Link to="/cart"><FontAwesome style={{top: "-3px", marginRight: "3px"}} name="shopping-bag"/> CART</Link>
					</li>
					<li>
						<Link to="/account"><FontAwesome style={{top: "-3px", marginRight: "3px"}} name="user"/> ACCOUNT</Link>
					</li>
					<li>
						<a href={config.sisterLabelLink}>{config.sisterLabel} {config.sisterLabel === "Radio Juicy" ? ( <img className="RJMenuLogo" src="/img/favicon-rj.png"/>) : null}</a>
					</li>
				</ul>
			</div>
		</div>
		
		);
	}
}

export { MobileMenu };
