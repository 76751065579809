import React, { Component } from 'react';
import { Ajax } from '../ajax/Ajax';
import $ from 'jquery';

class Subscribe extends Component {
	
	handleSubscribe() {
		var email = $("#ne").val();
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (! re.test(email)) {
			$("#message").text("Please enter a valid e-mail address.");
			return;
		}
		Ajax.subscribe(email).then(function(data) {
			$("#message").text(data);
		});
	}
	
	render() {
		return (
		<div className="Subscribe" id="subscribe">
			<input type="email" id="ne" size="30" required placeholder="E-Mail"></input>
			<div className="SubscribeButton" onClick={this.handleSubscribe} >Subscribe</div>
			<div id="message"></div>
		</div>
		);
	}
}

export { Subscribe };