var domain = window.location.href;
var urbanWaves = false;
var radioJuicy = false;
if (domain.includes("urbanwavesrecords.com")) {
	urbanWaves = true;
}
if (domain.includes("radiojuicy.com")) {
	radioJuicy = true;
}
if (domain.includes("localhost")) {
	radioJuicy = true;
}

var UrbanWavesConfig = {
	title: "Urban Waves",
	favicon: "favicon.ico",
	base: 'https://urbanwavesrecords.com/',
	wp_base: 'https://urbanwavesrecords.com/secure',
	wc_consumerKey: 'ck_4886003d8900ff7593eed69d0d25c1a5a5abc729',
    wc_consumerSecret: 'cs_b2d06dc7020cbb8903312d08115e07d8c88ba0c4',	
	rosterPageId: 59,
	footerPageId: 19,
	releaseCategory: 16,
	
	subscribeApiKey: "fa62c86f09",
	
	bandcamp: "http://urbanwavesrecords.bandcamp.com/",
	soundcloud: "https://soundcloud.com/urbanwaves",
	facebook: "https://www.facebook.com/urbanwavesrecords/",
	instagram: "https://www.instagram.com/urban_waves/",
	twitter: "https://twitter.com/urban_waves",
	youtube: "https://www.youtube.com/channel/UCNYzJJCoPVNR1kk1xjl8Wsw",
	spotify: "https://open.spotify.com/user/44ygp012woovnlorrd3uvopwd?si=hN27DI2iRE60-4mp8EB_PA",
	
	sisterLabel: "Radio Juicy",
	sisterLabelLink: "https://radiojuicy.com",
	
	copyRightName: "Urban Waves Records",
	
	logoBig: "/img/logo.png",
	logoSmall: "/img/logo-60.png",
	radioJuicy: false,
	urbanWaves: true
}

var RadioJuicyConfig = {
	title: "Radio Juicy",
	favicon: "faviconRJ.ico",
	base: 'https://radiojuicy.com/',
	wp_base: 'https://radiojuicy.com/secure',
	wc_consumerKey: "ck_4886003d8900ff7593eed69d0d25c1a5a5abc729",
	wc_consumerSecret: "cs_b2d06dc7020cbb8903312d08115e07d8c88ba0c4",
	rosterPageId: 59,
	footerPageId: 19,
	releaseCategory: 16,
	
	subscribeApiKey: "47626a1231",
	
	bandcamp: "http://radiojuicy.bandcamp.com/",
	soundcloud: "https://soundcloud.com/radiojuicy1",
	facebook: "https://www.facebook.com/radiojuicy/",
	instagram: "https://www.instagram.com/radiojuicy/",
	twitter: "https://twitter.com/Radio_Juicy",
	youtube: "https://www.youtube.com/channel/UCZk3dg0TqbPU74dUu_c53Rw",
	spotify: "https://open.spotify.com/user/radiojuicy",
	
	sisterLabel: "Urban Waves",
	sisterLabelLink: "https://urbanwavesrecords.com",
	
	copyRightName: "Radio Juicy",
	
	logoBig: "/img/logo-rj.png",
	logoSmall: "/img/logo-rj-60.png",
	radioJuicy: true,
	urbanWaves: false
}

var config = urbanWaves ? UrbanWavesConfig : RadioJuicyConfig;

export default config;