import React, { Component } from 'react';

class CartPopup extends Component {
	
	render() {		
		return (
		<div className="CartPopup">
			<div className="CartPopupInfo">Item added to cart!</div>
			<div className="CartPopupButtons">
				<div className="CartPopupButton" onClick={this.props.closeFunction}>Continue Shopping</div><br/>
				<div className="CartPopupButton"><a href="/cart">View Cart</a></div>
			</div>
		</div>
		);
	}
}

export { CartPopup };